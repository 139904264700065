import React from 'react';
import { Row, Container } from 'react-bootstrap';
import Notiflix from 'notiflix';
import creditsTextData from './creditsTextData.json';
import './CreditsText.scss';
import { useChangeBalanceMutation } from 'hooks/mutations/useChangeBalanceMutation';
import { Loader } from 'components/common/Loader';
import { useGetPaymentLinksQuery } from 'hooks/queries/useGetPaymentLinksQuery';

const CreditsText = () => {
  const { data: paymentLinks, isLoading: isLoadingLinks, error: linksError } = useGetPaymentLinksQuery();

  const handleTopupClick = amount => {
    if (!paymentLinks) return;

    const stripeUrls = {
      100: paymentLinks['Credit-Purchase-100'],
      50: paymentLinks['Credit-Purchase-50'],
      25: paymentLinks['Credit-Purchase-25'],
      10: paymentLinks['Credit-Purchase-10']
    };

    const width = 500;
    const height = 700;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const newWindow = window.open(stripeUrls[amount], '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    const checkPaymentWindow = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(checkPaymentWindow);
        refreshBalance();
      }
    }, 500);
  };

  const { mutate: refreshBalance } = useChangeBalanceMutation({
    onSuccess: () => {
      Notiflix.Notify.success('Balance updated successfully');
    },
    onError: () => {
      Notiflix.Notify.failure('Failed to update balance');
    }
  });

  if (isLoadingLinks) {
    return (
      <section className="loader-section">
        <Loader />
      </section>
    );
  }

  if (linksError) {
    return (
      <ul>
        <li>{creditsTextData[2]}</li>
        <li>{creditsTextData[3]}</li>
        <li>{creditsTextData[4]}</li>
        <li>{creditsTextData[5]}</li>
      </ul>
    );
  }

  return (
    <Container>
      <Row className="align-items-start justify-content-between mb-3">
        <h4>{creditsTextData[0]}</h4>
        <p>{creditsTextData[1]}</p>
        <ul>
          <li>
            <span onClick={() => handleTopupClick(10)} className="topup-link">
              {creditsTextData[2]}
            </span>
          </li>
          <li>
            <span onClick={() => handleTopupClick(25)} className="topup-link">
              {creditsTextData[3]}
            </span>
          </li>
          <li>
            <span onClick={() => handleTopupClick(50)} className="topup-link">
              {creditsTextData[4]}
            </span>
          </li>
          <li>
            <span onClick={() => handleTopupClick(100)} className="topup-link">
              {creditsTextData[5]}
            </span>
          </li>
        </ul>
        <p>{creditsTextData[6]}</p>
        <h4>{creditsTextData[7]}</h4>
        <p>{creditsTextData[8]}</p>
        <h4>{creditsTextData[9]}</h4>
        <ul>
          <li>{creditsTextData[10]}</li>
          <li>{creditsTextData[11]}</li>
          <li>{creditsTextData[12]}</li>
        </ul>
      </Row>
    </Container>
  );
};

export default CreditsText;
