import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import MainLayout from 'layouts/MainLayout';

import Page404 from 'pages/page404/Page404';
import Page500 from 'pages/page500/Page500';
import LoginPage from 'pages/login-page/LoginPage';
import LogoutPage from 'pages/logout-page/LogoutPage';
import RegistrationPage from 'pages/registration-page/RegistrationPage';
import SimpleForgetPassword from 'pages/forget-password-page/ForgetPasswordPage';
import DashboardPage from 'pages/dashboard-page/DashboardPage';
import ProjectPage from 'pages/project-view-page/ProjectViewPage';
import FileViewPage from 'pages/file-view/FileViewPage';
import RulesPage from 'pages/rules-page/RulesPage';
import CreditsPage from 'pages/сredits-page/CreditsPage';
import pagesRoutes from 'routes/pages-routes';
import ErrorLayout from 'layouts/ErrorLayout';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path={pagesRoutes.page404} element={<Page404 />} />
        <Route path={pagesRoutes.page500} element={<Page500 />} />
      </Route>

      <Route element={<AuthSimpleLayout />}>
        <Route
          path={pagesRoutes.login}
          element={
            <PublicRoute redirect={pagesRoutes.home}>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path={pagesRoutes.register}
          element={
            <PublicRoute redirect={pagesRoutes.home}>
              <RegistrationPage />
            </PublicRoute>
          }
        />
        <Route
          path={pagesRoutes.logout}
          element={
            <PublicRoute redirect={pagesRoutes.home}>
              <LogoutPage />
            </PublicRoute>
          }
        />
        <Route
          path={pagesRoutes.forgotPassword}
          element={
            <PublicRoute redirect={pagesRoutes.home}>
              <SimpleForgetPassword />
            </PublicRoute>
          }
        />
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        <Route
          path={pagesRoutes.home}
          element={
            <PrivateRoute redirect={pagesRoutes.login}>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path={pagesRoutes.rules}
          element={
            <PrivateRoute redirect={pagesRoutes.login}>
              <RulesPage />
            </PrivateRoute>
          }
        />
        <Route
          path={pagesRoutes.credits}
          element={
            <PrivateRoute redirect={pagesRoutes.login}>
              <CreditsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={pagesRoutes.projectView}
          element={
            <PrivateRoute redirect={pagesRoutes.login}>
              <ProjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path={pagesRoutes.fileView}
          element={
            <PrivateRoute redirect={pagesRoutes.login}>
              <FileViewPage />
            </PrivateRoute>
          }
        />
      </Route>

      {/* //--- MainLayout end  */}

      <Route path="*" element={<Navigate to={pagesRoutes.page404} replace />} />
    </Routes>
  );
};

export default FalconRoutes;
