import { useQuery } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

export const getFiles = async ({ projectISbb_datasheep_projectsID, limit, page }) => {
  const params = {
    projectISbb_datasheep_projectsID,
    page,
    limit
  };
  const res = await axios.get(`/app/bb_datasheep_projects_files`, { params });

  console.log('res.data:', res.data);

  return res.data;
};

export const useGetProjectFilesQuery = projectId => {
  return useQuery({
    queryFn: () => getFiles({ projectISbb_datasheep_projectsID: projectId, page: 0, limit: 10000 }),
    queryKey: ['GET_PROJECT_FILES', projectId],
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    placeholderData: true
  });
};
