import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { getFiles } from 'modules/FilesTable/FilesTable';

const ExportModal = ({ show, onHide, selectedRowIds, projectId }) => {
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    if (projectId) {
      const fetchData = async () => {
        try {
          const result = await getFiles({ projectISbb_datasheep_projectsID: projectId, page: 0, limit: 10000 });
          console.log('Fetched Files Data:', result?.data);
          setRowsData(result?.data || []);
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      };
      fetchData();
    }
  }, [projectId]);

  const exportToXLSX = () => {
    const selectedData = rowsData.filter(row => selectedRowIds.includes(row._id));

    if (!Array.isArray(selectedData) || selectedData.length === 0) {
      console.error('No data available for export.');
      return;
    }

    const formattedData = selectedData.map(row => {
      const fieldsObject = row.fields.reduce((acc, field) => {
        acc[field.field_name] = field.valueISsmallplaintextbox;
        return acc;
      }, {});

      return {
        File: row.name,
        Text: row.extracted_textISsmallplaintextbox,
        ...fieldsObject
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SelectedData');

    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  const exportToCSV = () => {
    const selectedData = rowsData.filter(row => selectedRowIds.includes(row._id));

    if (selectedData.length === 0) {
      console.error('No data available for export.');
      return;
    }

    const formattedData = selectedData.map(row => {
      const fieldsObject = row.fields.reduce((acc, field) => {
        acc[field.field_name] = field.valueISsmallplaintextbox;
        return acc;
      }, {});

      return {
        File: row.name,
        Text: row.extracted_textISsmallplaintextbox,
        ...fieldsObject
      };
    });

    const csvContent = [Object.keys(formattedData[0]).join(','), ...formattedData.map(row => Object.values(row).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'exported_data.csv';
    a.click();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Export Files</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Select the format to export:</p>
        <div className="d-flex justify-content-between">
          <Button variant="primary" onClick={exportToXLSX} className="me-3" style={{ width: 'calc(50% - 10px)' }}>
            Export to .xlsx
          </Button>
          <Button variant="secondary" onClick={exportToCSV} style={{ width: 'calc(50% - 10px)' }}>
            Export to .csv
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ExportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedRowIds: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ExportModal;
