export default {
  home: '/',
  projectView: '/projectView/:id',
  fileView: '/fileView/:id',
  page404: 'errors/404',
  page500: 'errors/500',
  login: '/login',
  forgotPassword: '/forgot-password',
  logout: '/logout',
  register: '/register',
  rules: '/rules',
  credits: '/credits'
};
